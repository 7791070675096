import React, { useState } from 'react';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import Section from '../Section/Section';
import PartnerField from './PartnerField';
import PartnerTextarea from './PartnerTextarea';
import { ArrowLinkLargeDiagonal } from '../ArrowLink/ArrowLink';
import { TagSelect, TagSelectItem, TagSlash } from '../TagSelect/TagSelect';
import { fontstack } from '../../utils/fontstack'
import { type } from '../../utils/type';
import { media } from '../../utils/mediaQuery';
import { encodeToString } from '../../utils/encodeToString';

const Wrapper = styled.div`
  overflow: hidden;
  background: #383B23;
`

const BigText = styled.h2`
  ${fontstack.default}
  color: #F3EFD7;
  text-transform: uppercase;
  font-weight: 300;
  font-size: 13vw;
  letter-spacing: 0.030em;
  line-height: 0.85;
  margin: 96px auto 50px;
  text-align: center;
  width: 80%;

  ${media.medium`
    margin: 256px auto 150px;
    line-height: 0.78;
  `}
`

const BigTextMobile = styled(BigText)` 
  margin: 140px auto 50px;
  ${media.large`
    display: none;
  `}
`

const BigTextDesktop = styled(BigText)` 
  display: none;
  ${media.large`
    display: block;
  `}
`

const FormWrapper = styled.form`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0 0 14px;
`

const FormSection = styled(Section)`
  position: relative;
  margin-top: 0;
  overflow: hidden;
`

const FormSubmitPrompt = styled.div` 
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #383B23;
  z-index: 1;
  opacity: ${props => props.active ? '1' : '0'};
  pointer-events: ${props => props.active ? 'auto' : 'none'};
  transition: opacity .2s ease;
  color: #F3EFD7;
  display: flex;
  justify-content: flex-end;
  ${fontstack.default}
  ${type('m-large')}
  font-weight: 300;

  ${media.large`
    ${type('large')}
    font-weight: 300;
  `}

`

const Statement = styled.p`
  ${fontstack.default}
  ${type('caption')}
  color: #F3EFD7;
  margin: 0 0 15px;
  text-align: center;

  ${media.large`
    ${type('body')}
    margin: 0 0 30px;
  `}
`

const Group = styled.div` 
  position: relative;
  width: 100%;
  margin: 0 0 50px;
  text-align: center;

  ${media.large`
    margin: 0 0 100px;
  `}

  &:last-child {
    margin: 0;
  }
`

const CtaLine = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-end;

  margin: 30px 0 0;

  ${media.large`
    margin: 100px 0 0;
  `}
`

const CtaWrapper = styled.button` 
  
`

const PartnerForm = () => {
  const { register, handleSubmit, reset, setValue, formState: { errors } } = useForm();
  const [ promptActive, setPromptActive ] = useState(false);

  const onSubmit = (data) => {
    const body = encodeToString({
      "form-name": "partner-form",
      ...data,
    });

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: body,
    })
      .then(() => firePrompt("Thank you - we'll be reaching out in no time."))
      .catch((error) => firePrompt('Error. Please try again.'));
  }

  const firePrompt = (msg) => {
    setPromptActive(msg);
    setTimeout(() => {
      reset();
      setPromptActive(false);
    }, 4000);

}

  return (
    <Wrapper>
      <BigTextMobile>here’s<br/>your<br/>blank<br/>canvas.</BigTextMobile>
      <BigTextDesktop>here’s your blank canvas.</BigTextDesktop>
      <FormSection large medium>
        
        <FormWrapper onSubmit={handleSubmit(onSubmit)} data-netlify="true" name="partner-form">
          <Group>
            <Statement>Personal Information:</Statement>
            <PartnerField type="text" placeholder="Name" {...register('name',{required: true})}/>
            <PartnerField type="text" placeholder="Email" {...register('email',{required: true, pattern: /^\S+@\S+$/i})}/>
          </Group>
          <Group>
            <Statement>Your purpose:</Statement>
            <TagSelect>
              <TagSelectItem {...register("purpose", { required: true })} type="radio" value="Collaboration" theme='light'>Collaboration</TagSelectItem>
              <TagSlash theme='light'/>
              <TagSelectItem {...register("purpose", { required: true })} type="radio" value="Ambassador" theme='light'>Ambassador</TagSelectItem>
              <TagSlash theme='light'/>
              <TagSelectItem {...register("purpose", { required: true })} type="radio" value="Others" theme='light' >Others</TagSelectItem>
            </TagSelect>
          </Group>
          <Statement>Tell us more. Why and how would you like to work with us? Or what is your feedback?</Statement>
          <PartnerTextarea placeholder={'💡✏️🤝'}  {...register('comments',{required: true})}/>
          <CtaLine>
            <FormSubmitPrompt active={promptActive}>
              {promptActive}
            </FormSubmitPrompt>
            <CtaWrapper type="submit">
              <ArrowLinkLargeDiagonal theme='light' active>Let’s do this.</ArrowLinkLargeDiagonal>
            </CtaWrapper>
          </CtaLine>
         
          <input type="hidden" name="form-name" value="partner-form" />
        </FormWrapper>
      </FormSection>
    </Wrapper>
  )
}

export default PartnerForm;