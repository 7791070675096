import React from 'react';
import styled from 'styled-components';
import { fontstack } from '../../utils/fontstack';
import { type } from '../../utils/type';
import { media } from '../../utils/mediaQuery';

const Wrapper = styled.div` 

`

const Title = styled.h2`
  ${fontstack.default}
  ${type('m-large')}
  margin: 0 0 20px; 
  color: #383B23;
  font-weight: 300;

  ${media.large`
    ${type('large')}
    font-weight: 300;
    margin: 0 0 40px; 
  `}
`

const List = styled.ol`
  ${fontstack.default}
  ${type('m-large')}
  font-weight: 300;
  margin: 0 0 20px; 
  list-style: none;
  counter-reset: li;
  padding: 0;
  color: #383B23;

  ${media.large`
    ${type('large')}
    font-weight: 300;
  `}

  & > li {
    counter-increment: li;
    display: flex;
    align-items: flex-start;

    ${media.large`
      align-items: center;
    `}
  }

  & > li:before {
    content: counter(li);
    border: 1px solid #383B23;
    border-radius: 50%;
    width: 33px;
    height: 33px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 13px 0 0;
    flex-shrink: 0;

    ${media.large`
      width: 64px;
      height: 64px;
      margin: 0 24px 0 0;
    `}
  }

  &:last-child{
      margin-bottom: 0;
  }

`

const BigList = ({title, children}) =>{

  return (
    <Wrapper>
      <Title>{title}</Title>
      <List>
        { children }
      </List>

    </Wrapper>
  )
}

export { BigList }

const Item = styled.li` 
  padding: 20px 0 20px;
  border-top: 1px solid #383B23;

  ${media.large`
    padding: 40px 0 40px 100px;
  `}
`

const ItemInner = styled.div`
  flex: 1;
`

const BigListItem = ({children}) => {

  return (
    <Item>  
      <ItemInner>
        {children}
      </ItemInner>
    </Item>
  )
}

export { BigListItem }