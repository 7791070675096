import React from 'react';
import styled from 'styled-components';
import { graphql, useStaticQuery } from "gatsby"
import { fontstack } from '../../utils/fontstack';
import { type } from '../../utils/type';
import { media } from '../../utils/mediaQuery';
import IconGoogle from './assets/google.svg';
import IconBupa from './assets/bupa.svg';
import IconSoho from './assets/soho.svg';
import IconBasehall from './assets/basehall.svg';
import IconBloomberg from './assets/bloomberg.svg';
import IconMarket from './assets/tongchong.svg';
import IconK11 from './assets/k11.svg';
import IconAsaya from './assets/asaya.svg';
import IconTack from './assets/tack.svg';
import IconNewMoon from './assets/newmoon.svg';
import IconBeWell from './assets/bewell.svg';
import IconBRT from './assets/brt.svg';

const Wrapper = styled.div`
  position: relative;  
`

const Title = styled.h2` 
  ${fontstack.default}
  ${type('m-large')}  
  font-weight: 300;
  text-align: center; 
  color: #383B23;
  margin: 20px 0 147px;

  ${media.large`
    ${type('large')}
    font-weight: 300;
    margin: 0 0 50px;
  `}
`

const SubTitle = styled.p` 
  ${fontstack.default}
  ${type('m-body')}  
  text-align: center; 
  color: #383B23;
  font-weight: 500;
  margin: 40px 0 0;

  ${media.large`
    ${type('body')}
    font-weight: 500;
  `}
`

const PartnerGrid = styled.div` 
  display: grid;
  grid-template-columns: repeat(2,1fr);
  border-top: 1px solid #383B23;
  border-bottom: 1px solid #383B23;

  ${media.large`
    grid-template-columns: repeat(4,1fr);
  `}
`

const BrandWrapper = styled.div` 
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px;
  border-bottom: 1px solid #383B23;

  & > svg {
    width: 100%;
    height: auto;
  }

  ${media.large`
    height: 300px;
  `}

  &:nth-child(2n+1):nth-last-child(-n + 2), 
  &:nth-child(2n+1):nth-last-child(-n + 2) ~ &{
    border-bottom: none;
  }

  ${media.large`
    &:nth-child(4n+1):nth-last-child(-n + 4), 
    &:nth-child(4n+1):nth-last-child(-n + 4) ~ &{
      border-bottom: none;
    }
  `}
  
`

const BrandIcon = styled.img` 
  positive: relative;
  width: 100%;
`

const PartnerBrands = () => {
  const {
    prismicPartnerBrands: { data }
  } = useStaticQuery(graphql`
    query {
      prismicPartnerBrands {
        data {
          brands {
            link {
              url
            }
            image {
              url
              alt
              dimensions {
                height
                width
              }
            }
          }
        }
      }
    }
  `);


  return (
    <Wrapper>
      <Title>Our Partners (so far)</Title>
      <PartnerGrid>
        {data && data.brands ?
          data.brands.map((brand) => {
            return <BrandWrapper>
              <BrandIcon src={brand.image.url} alt={brand.image.alt} />
            </BrandWrapper>
          }) : null  
        }
      </PartnerGrid>
      <SubTitle>and of course, our family and friends as always.</SubTitle>
    </Wrapper>
  )
}

export default PartnerBrands;