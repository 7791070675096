import React, { useEffect, useState } from "react"
import styled from 'styled-components';
import { Seo } from "../components/seo";
import { Layout } from "../components/Layout/layout"
import { media } from '../utils/mediaQuery';
import { StoreContext } from '../context/store-context';
import Bracket from "../components/Bracket/Bracket";
import Section from "../components/Section/Section";
import PartnerHead from "../components/PartnerHead/PartnerHead";
import PartnerHeadMobile from "../components/PartnerHead/PartnerHeadMobile";
import { BigList, BigListItem } from '../components/BigList/BigList';
import PartnerForm from "../components/PartnerForm/PartnerForm";
import PartnerBrands from "../components/PartnerBrands/PartnerBrands";

const BrandSection = styled.div` 
  margin: -80px 0 0;
  ${media.large`
    margin: 0;
  `}
`

export default function PartnerPage() {
  const { width } = React.useContext(StoreContext);
  return (
    <Layout navBorder>
      <Seo
          title='Partner'
          description='Let’s be partners and grow health together.'
        />
      <Section none>
        { width && width >= 1080 ? <PartnerHead /> : width && width < 1080 ? <PartnerHeadMobile /> : null }
      </Section>
      <Bracket bottom bottomShift zIndex={1} background='#F2EADE' nopad>
        <Section none>
          <BigList title='If you would like to:'>
            <BigListItem>Collaborate/partner with us,</BigListItem>
            <BigListItem>Become a nüte ambassador,</BigListItem>
            <BigListItem>Share any thoughts you have of us,</BigListItem>
          </BigList>
        </Section>
      </Bracket>
      <PartnerForm />
      <Bracket top bottom topShift zIndex={1} background='#F2EADE'>
        <BrandSection>
          <Section>
          <PartnerBrands />
          </Section>
        </BrandSection>
      </Bracket>
    </Layout>
  )
}