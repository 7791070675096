import React from 'react';
import styled from 'styled-components';
import { fontstack } from '../../utils/fontstack';
import { type } from '../../utils/type';
import { media } from '../../utils/mediaQuery';

const TagWrapper = styled.div`
  display: flex;
  ${fontstack.default}
  ${type('large')}
  font-weight: 300;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  color: ${props => props.theme === 'light' ? '#F2EADE': '#383B23'};
`

const TagSelect = (props) => {
  const { children, name, callback, theme } = props;

  return (
    <TagWrapper theme={theme} >
      {children}
    </TagWrapper>
  )
}

export { TagSelect }

const LabelWrapper = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: 0 15px;
`

const Label = styled.div`
  ${fontstack.default}
  ${type('m-large')}
  border: 1px solid ${props => props.theme === 'light' ? '#F2EADE': '#383B23'};
  border-radius: 10px;
  font-weight: 300;
  padding: 5px 10px;
  margin: 0 0 10px;
  color: ${props => props.theme === 'light' ? '#F2EADE': '#383B23'};
  user-select: none;

  ${media.large`
    ${type('large')}
    border-radius: 20px;
    font-weight: 300;
    padding: 20px 30px;
    margin: 0 0 30px;
  `}
`

const Input = styled.input`
  display: none;

  &:checked ~ ${Label} {
    background: ${props => props.theme === 'light' ? '#F2EADE' : '#383B23'};
    color: ${props => props.theme === 'light' ? '#383B23': '#F2EADE'};
  }
`

const TagSelectItem = React.forwardRef((props,ref) => {
  const {children, theme, ...rest } = props;

  return (
    <LabelWrapper>
      <Input ref={ref} theme={theme} {...rest}/>
      <Label theme={theme}>{children}</Label>   
    </LabelWrapper>
  )

})

export { TagSelectItem }

const Slash = styled.div`
  ${fontstack.default}
  ${type('m-large')}
  font-weight: 300;
  margin: 0 0 10px;
  color: ${props => props.theme === 'light' ? '#F2EADE': '#383B23'};
  user-select: none;

  ${media.large`
    ${type('large')}
    font-weight: 300;
    margin: 0 0 30px;
    padding: 20px 0;
  `}
`

const TagSlash = ({theme}) => {
  return (
    <Slash theme={theme}>/</Slash>
  )
}

export { TagSlash };