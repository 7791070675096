import React, { useState, useCallback, useEffect} from 'react';
import styled from 'styled-components';
import { gsap } from "gsap";
import { ScrollTrigger } from 'gsap/all';
import { fontstack } from '../../utils/fontstack';
import { StoreContext } from '../../context/store-context';
import { media } from '../../utils/mediaQuery';
import EarthImage from './assets/earth.png';
import TwinsImage from './assets/twins.png';
import ShortImage from './assets/short.png';
import LongImage from './assets/long.png';
gsap.registerPlugin(ScrollTrigger);

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 80px 0 0;
  ${media.large`
    padding: 0;
  `}
`

const BigTextWrapper = styled.div` 
  position: relative;
  display: flex;
  flex-direction: column;

  ${media.large`
    align-self: flex-end;
  `}
`

const BigText = styled.h2`
  ${fontstack.default}
  color: #383B23;
  text-transform: uppercase;
  font-weight: 300;
  font-size: 14vw;
  letter-spacing: 0.030em;
  line-height: 1;
  margin: 10vw 0 10vw;
`

const MiddleText = styled(BigText)` 
  margin: 10vw 0 10vw 0;
  align-self: flex-end;

  ${media.large`
    margin: 10vw 60px 10vw 0;
  `}
`

const LastText = styled(BigText)` 
  align-self: center;
  margin: 10vw 0 20vw 0;
  ${media.large`
    margin: 10vw 0 10vw 0;
  `}
`

const Hanger = styled.div` 
  position: relative;
  margin: 20px 0 0;

  ${media.large`
    position: absolute;
    margin: 0;
  `}
`

const HangerInner = styled.div` 
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column-reverse;
  ${media.large`
    left: -100%;
    flex-direction: row;
  `}
`

const Circle = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='100' ry='100' stroke='%23383B23' stroke-width='2' stroke-dasharray='10%2c 10' stroke-dashoffset='20' stroke-linecap='butt'/%3e%3c/svg%3e");
  ${media.large`
    width: 160px;
    height: 160px;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='100' ry='100' stroke='%23383B23' stroke-width='3' stroke-dasharray='10%2c 10' stroke-dashoffset='20' stroke-linecap='butt'/%3e%3c/svg%3e");
  `}
`



const Line = styled.div` 
  position: relative;
  width: 1px;
  height: 50px;
  background-color: #383B23;

  ${media.large`
    width: 150px;
    height: 2px;
  `}
`

const Statement = styled.p` 
  ${fontstack.default}
  font-size: calc(18px + 1.2vw);
  letter-spacing: 0.030em;
  line-height: 1.1;
  color: #383B23;
  font-weight: 300;
  align-self: flex-start;

  ${media.large`
    margin: 0 0 0 20px;
    font-size: calc(18px + 2vw);
  `}
`

const ContentRow = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;

  ${media.large`
    flex-direction: row;
  `}
`

const EarthEmoji = styled.img` 
  width: 65%;
  position: relative;
  transform: perspective(2000px) rotateY(0deg);

  ${media.large`
    width: 100px;
  `}
`

const TwinsEmoji = styled.img` 
  width: 75%;
  position: relative;

  ${media.large`
    width: 120px;
  `}
`

const CircleInner = styled.div` 
  width: 60%;
  display: grid;
  justify-content: center;
`
const PeopleEmoji = styled.img`
  width: 100%;
  position: relative;
  top: -5px;
  grid-area: 1 / 1;
`


const ShortEmoji = styled(PeopleEmoji)` 
  transform: perspective(2000px) rotateY(0deg);
`

const LongEmoji = styled(PeopleEmoji)` 
  transform: perspective(2000px)  rotateY(90deg)};
`

const PartnerHeadMobile = () => {
  const Triggers = React.useRef([]);
  const [ FirstStatementRef, setFirstStatementRef ] = useState();
  const [ SecondStatementRef, setSecondStatementRef ] = useState();
  const [ EarthTrigger, setEarthTrigger ] = useState(false);
  const [ PeopleTrigger, setPeopleTrigger ] = useState(false);
  const [ ShortTrigger, setShortTrigger ] = useState(false);
  const [ LongTrigger, setLongTrigger ] = useState(false);
  useEffect(() => {
    if(FirstStatementRef && SecondStatementRef) {
      Triggers.current.push(ScrollTrigger.create({
        trigger: FirstStatementRef,
        start: "top 200px",
        end:"+=200 200px",
        markers: false,
        onUpdate: (self) => {
          gsap.set(EarthTrigger,{rotateY: self.progress * 180});
        }
      }));

      Triggers.current.push(ScrollTrigger.create({
        trigger: SecondStatementRef,
        start: "top 200px",
        end: "+=200 200px",
        markers: false,
        onUpdate: (self) => {

          if(self.progress <= 0.5) {
            gsap.set(ShortTrigger,{rotateY: (self.progress/0.5) * 90});
            gsap.set(LongTrigger,{rotateY: 90});
          } else {
            const longProgress = self.progress - 0.5;
            gsap.set(ShortTrigger,{rotateY: 90});
            gsap.set(LongTrigger,{rotateY: 90 - ((longProgress/0.5) * 90)});
          }
          

          
        }
      }));

    }

    return () => {
      if(Triggers.current) {
        Triggers.current.forEach((trigger) => {
          trigger.kill();
        });
      }
    }
  },[FirstStatementRef, SecondStatementRef])

  const EarthReference = useCallback((node) => {
    if(node !== null) {
      setEarthTrigger(node);
    }
  }, [])

  const ShortReference = useCallback((node) => {
    if(node !== null) {
      setShortTrigger(node);
    }
  }, [])

  const LongReference = useCallback((node) => {
    if(node !== null) {
      setLongTrigger(node);
    }
  }, [])

  const FirstStatementReference = useCallback((node)=> {
    if(node !== null) {
      setFirstStatementRef(node);
    }
  }, [])

  const SecondStatementReference = useCallback((node)=> {
    if(node !== null) {
      setSecondStatementRef(node);
    }
  }, [])

  return (
    <Wrapper>
      <BigText>growing</BigText>
      <BigTextWrapper>
        <ContentRow>
        <Statement ref={FirstStatementReference}>The ultimate goal is a clean world<br/>that would thrive and last.</Statement>
          <Hanger>
            <HangerInner>
              <Circle>
                <EarthEmoji ref={EarthReference} src={EarthImage} alt="Icon of an earth" />
              </Circle>
              <Line/>
            </HangerInner>
          </Hanger>
          
        </ContentRow>
        <MiddleText>health</MiddleText>
        <ContentRow>
          <Statement ref={SecondStatementReference}>Each of us humans have our unique superpowers to this cause, and here is a safe-place to unite — exchange, progress, and amplify our ideas to improve all health on Earth.</Statement>
          <Hanger>
            <HangerInner>
              <Circle>
                <CircleInner>
                  <ShortEmoji ref={ShortReference} src={ShortImage} alt="Short haired person" />
                  <LongEmoji ref={LongReference} src={LongImage} alt="Long haired person" />
                </CircleInner>
              </Circle>
              <Line/>
            </HangerInner>
          </Hanger>
        </ContentRow>
      </BigTextWrapper>
      <LastText>together</LastText>
    </Wrapper>
  )
}

export default PartnerHeadMobile;