import React from 'react';
import styled from 'styled-components';
import { fontstack } from '../../utils/fontstack';
import { type } from '../../utils/type';
import { media } from '../../utils/mediaQuery';

const Input = styled.input` 
  ${fontstack.default}
  ${type('m-large')}
  font-weight: 300;
  color: #F3EFD7;
  border: 1px solid #F3EFD7;
  border-radius: 10px;
  outline: none;
  background: transparent;
  width: 100%;
  box-sizing: border-box;
  padding: 5px 10px;
  margin: 0 0 10px;

  ${media.large`
    ${type('large')}
    font-weight: 300;
    padding: 20px 30px;
    border-radius: 20px;
    margin: 0 0 30px;
  `}

  &::placeholder {
    color: #F3EFD7;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover, 
  &:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px #383B23 inset;
    background-color: #383B23 !important;
    background-clip: content-box !important;
    -webkit-text-fill-color: #F3EFD7;
  }
`

const PartnerField = React.forwardRef((props, ref) => {

  return (
    <Input ref={ref} {...props}/>
  )
});

export default PartnerField;

