import React, { useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { gsap } from "gsap";
import { ScrollTrigger } from 'gsap/all';
import { fontstack } from '../../utils/fontstack';
import { StoreContext } from '../../context/store-context';
import { media } from '../../utils/mediaQuery';
import EarthImage from './assets/earth.png';
import TwinsImage from './assets/twins.png';
import ShortImage from './assets/short.png';
import LongImage from './assets/long.png';
gsap.registerPlugin(ScrollTrigger);

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 80px 0 0;
  ${media.large`
    padding: 0;
  `}
`

const BigTextWrapper = styled.div` 
  position: relative;
  display: flex;
  flex-direction: column;

  ${media.large`
    align-self: flex-end;
  `}
`

const BigText = styled.h2`
  ${fontstack.default}
  color: #383B23;
  text-transform: uppercase;
  font-weight: 300;
  font-size: 14vw;
  letter-spacing: 0.030em;
  line-height: 1;
  overflow: hidden;
`

const FirstText = styled(BigText)` 
  margin: 10vw 0 0 0;
`

const BigTextInner = styled.span`
  position: relative;
  transform: ${props => props.active ? 'translate(0,0%)' : 'translate(0,-100%)'};
  transition: transform 1s ease 1s;
  display: block;

`

const MiddleText = styled(BigText)` 
  margin: 0;
  align-self: flex-start;
  background: #F2EADE;
  z-index: 3;
  overflow: visible;
  ${media.large`

  `}
`

const MiddleTextInner = styled.span`
  position: relative;
  transform: translate(150%,0);
  display: block;
`

const MiddleTextSpacer = styled.div`
  background: #F2EADE;
  height: 7vw;
  width: 100%;
  z-index: 1;
  position: relative;
`

const LastText = styled(BigText)` 
  align-self: center;
  overflow: visible;
  //margin: 10vw 0 20vw 0;
  ${media.large`
    margin: 0 0 10vw 0;
  `}
`

const LastTextLeft = styled.span`
  position: relative;
  transform: translate(-250%,0);
  display: inline-block;
`

const LastTextRight = styled.span`
  position: relative;
  transform:  translate(250%,0);
  display: inline-block;
`

const Hanger = styled.div` 
  position: relative;
  margin: 20px 0 0;

  ${media.large`
    position: absolute;
    margin: 0;
  `}
`

const HangerOne = styled(Hanger)` 
`

const HangerTwo = styled(Hanger)`
  ${media.large`
    display: none;
  `}
`

const HangerInner = styled.div` 
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column-reverse;
  ${media.large`
    left: -100%;
    flex-direction: row;
  `}
`

const Circle = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='100' ry='100' stroke='%23383B23' stroke-width='2' stroke-dasharray='10%2c 10' stroke-dashoffset='20' stroke-linecap='butt'/%3e%3c/svg%3e");
  ${media.large`
    display: grid;
    width: 160px;
    height: 160px;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='100' ry='100' stroke='%23383B23' stroke-width='3' stroke-dasharray='10%2c 10' stroke-dashoffset='20' stroke-linecap='butt'/%3e%3c/svg%3e");
  `}
`

const Line = styled.div` 
  position: relative;
  width: 1px;
  height: 50px;
  background-color: #383B23;

  ${media.large`
    width: 150px;
    height: 2px;
  `}
`

const Statement = styled.p` 
  ${fontstack.default}
  font-size: clamp(18px, 2.6vw, 2000px); 
  letter-spacing: 0.030em;
  line-height: 1.1;
  color: #383B23;
  font-weight: 300;
  align-self: flex-start;

  ${media.large`
    margin: 0 0 0 20px;
  `}
`

const ContentRow = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 10vw 0 3vw 0;

  ${media.large`
    flex-direction: row;
  `}
`

const ContentRowAnimated = styled(ContentRow)` 

  & ${Statement} {
    opacity: ${props => props.active ? '1' : '0'};
    transition: opacity .2s ease 1s;
  }

  & ${Line} {
    width: ${props => props.active ? '150px' : '0px'};
    transition: width 0.4s ease 1s;
  } 

  & ${Circle} {
    width: ${props => props.active ? '160px' : '0px'};
    height: ${props => props.active ? '160px' : '0px'};
    transition: width 0.6s ease 1.4s, height 0.6s ease 1.4s;
  }
`

const ContentRowSecond = styled(ContentRow)`
  margin: 10vw 0 10vw 0;
`

const EarthEmoji = styled.img` 
  width: 65%;
  position: relative;
  transition: transform .05s linear;
  will-change: transform;
  ${media.large`
    width: 100px;
  `}
`

const PeopleEmoji = styled.img`
  width: 65%;
  position: relative;
  top: -5px;
  grid-area: 1 / 1;
  transition: transform .05s linear;
  will-change: transform;
  ${media.large`
    width: 100px;
  `}
`

const ShortEmoji = styled(PeopleEmoji)` 
  ${media.large`
    opacity: 0;
    //transform: perspective(2000px)  rotateY(90deg) translate(0,0);
  `}
`

const LongEmoji = styled(PeopleEmoji)` 
  ${media.large`
    //transform: perspective(2000px)  rotateY(90deg);
  `}
`

const PartnerHead = () => {
  const { width } = React.useContext(StoreContext);
  const [ bigTextActive, setBigTextActive ] = React.useState(false);
  const [ lastTextActive, setLastTextActive ] = React.useState(false);
  const Triggers = React.useRef([]);
  const [ MiddleTextRef, setMiddleTextRef ] = React.useState(false);
  const [ LastTextLeftRef, setLastTextLeftRef ] = React.useState(false);
  const [ LastTextRightRef, setLastTextRightRef ] = React.useState(false);
  const [ ContentRowAnimatedActive, setContentRowAnimatedActive ] = React.useState();
  const [ ShortRef, setShortRef ] = React.useState();
  const [ LongRef, setLongRef ] = React.useState();
  const [ EarthRef, setEarthRef ] = React.useState();
  const [ HealthRef, setHealthRef ] = React.useState();
  const [ RowOne, setRowOne ] = React.useState();
  const [ RowTwo, setRowTwo ] = React.useState();
  const [ HangerOneStateRef, setHangerOne ] = React.useState();
  const [ HangerTwoStateRef, setHangerTwo ] = React.useState();


  const ShortReference = React.useCallback((node) => {
    if(node!==null) {
      setShortRef(node);
    }
  },[]) 

  const LongReference = React.useCallback((node) => {
    if(node!==null) {
      setLongRef(node);
    }
  },[]) 

  const MiddelTextReference = React.useCallback((node) => {
    if(node!==null) {
      setMiddleTextRef(node);
    }
  },[]) 

  const LastTextLeftReference = React.useCallback((node) => {
    if(node!==null) {
      setLastTextLeftRef(node);
    }
  },[]) 

  const LastTextRightReference = React.useCallback((node) => {
    if(node!==null) {
      setLastTextRightRef(node);
    }
  },[]) 

  const EarthReference = React.useCallback((node) => {
    if(node!==null) {
      setEarthRef(node);
    }
  },[]) 

  const HealthReference = React.useCallback((node) => {
    if(node!==null) {
      setHealthRef(node);
    }
  },[]) 

  const rowOne = React.useCallback((node) => {
    if(node!==null) {
      setRowOne(node);
    }
  },[]) 

  const rowTwo = React.useCallback((node) => {
    if(node!==null) {
      setRowTwo(node);
    }
  },[]) 

  const HangerOneRef = React.useCallback((node) => {
    if(node!==null) {
      setHangerOne(node);
    }
  },[]) 

  const HangerTwoRef = React.useCallback((node) => {
    if(node!==null) {
      setHangerTwo(node);
    }
  },[]) 

  useEffect(()=>{
      /*LongRef.current.style.transform = 'perspective(2000px)  rotateY(90deg) translate(0,0)';
      ShortRef.current.style.transform = 'perspective(2000px)  rotateY(90deg) translate(0,0)';*/

      if(Triggers.current) {
        Triggers.current.forEach((trigger) => {
          trigger.kill();
        });
      }

      if(RowOne 
        && RowTwo 
        && HangerOneStateRef
        && HangerTwoStateRef
        && HealthRef
        && EarthRef
        && ShortRef
        && LongRef
        && MiddleTextRef
        && LastTextLeftRef
        && LastTextRightRef
        ) {
        setBigTextActive(true);
        setContentRowAnimatedActive(true);
        
        const healthBound = HealthRef.getBoundingClientRect();
        const rowOneBound = RowOne.getBoundingClientRect();
        const rowTwoBound = RowTwo.getBoundingClientRect();
        const healthHeight = healthBound.height;
        const rowTwoHeight = rowTwoBound.height;
  
        let rowOneEnd = (healthBound.top - rowOneBound.top) + ((healthHeight - rowOneBound.height) / 2);
        
        let adjust = (healthBound.top - rowTwoBound.top) - ((rowTwoHeight - healthHeight) / 2);
        gsap.set(RowTwo, { y: adjust });
  
        Triggers.current.push(ScrollTrigger.create({
          trigger: RowOne,
          start: "center center",
          end: `+=${rowOneEnd+2} center`,
          pin: true,
          markers: false,
          onUpdate: (self) => {
            gsap.set(EarthRef, { opacity: 1 - self.progress});
          },
          onEnter: () => {
            
          },
          onLeave: () => {
            gsap.set(HangerOneStateRef, {display: 'none'});
          },
          onEnterBack: () => {
            gsap.set(HangerOneStateRef, {display: 'block'});
          }
        }));

        Triggers.current.push(ScrollTrigger.create({
          trigger: RowOne,
          endTrigger: HealthRef,
          start: "center center",
          end: `top 55%`,
          onEnter: () => {
            
          },
          onUpdate: (self) => {
            const percent = 150 - self.progress * 150;
            gsap.to(MiddleTextRef, { x: `${percent}%`, duration: .2});
          }
        }));

        Triggers.current.push(ScrollTrigger.create({
          trigger: HealthRef,
          start: "center center",
          end: `+=${adjust*-1} center`,
          pin: RowTwo,
          markers: false,
          onUpdate: (self) => {
            const lastLeftPercent = -250 + self.progress * 250;
            gsap.to(LastTextLeftRef, { x: `${lastLeftPercent}%`, duration: .2});
            const lastRightPercent = 250 - self.progress * 250;
            gsap.to(LastTextRightRef, { x: `${lastRightPercent}%`, duration: .2});

            const percentShort = self.progress / 0.5;
            if(percentShort <= 1) {
              const shortVal = 90 + 180*percentShort;
              gsap.set(ShortRef, { opacity: percentShort});
            } else if(percentShort > 1) {
              gsap.set(ShortRef, { opacity: 1});
            }

            const percentLongRotate = (self.progress - 0.75) / 0.25;
            const percentShortRotate = (self.progress - 0.5) / 0.25;

            if(percentShortRotate <= 1 && percentShortRotate >= 0) {
              const shortRotateVal = 0 + 90*percentShortRotate;
              gsap.set(ShortRef, { rotateY: shortRotateVal});
            } else if(percentShortRotate > 1) {
              gsap.set(ShortRef, { rotateY: 90});
            } else {
              gsap.set(ShortRef, { rotateY: 0});
            }

            if(percentLongRotate <= 1 && percentLongRotate >= 0) {
              const longVal = 90 + 90*percentLongRotate;
              gsap.set(LongRef, { rotateY: longVal});
            } else if(percentLongRotate < 0) {
              gsap.set(LongRef, { rotateY: 90});
            }
          },
          onLeaveBack: () => {
            gsap.set(HangerTwoStateRef, {display: 'none'});
          },
          onEnter: () => {
            gsap.set(HangerTwoStateRef, {display: 'block'});
          }, 
          onLeave: () => {
            setLastTextActive(true);
          }
        }));
      }
      
        
      
    
    return () => {
      if(Triggers.current) {
        Triggers.current.forEach((trigger) => {
          trigger.kill();
        });
      }
    }
  },[ 
    RowOne, 
    RowTwo, 
    HangerOneStateRef, 
    HangerTwoStateRef, 
    HealthRef,
    EarthRef,
    ShortRef,
    LongRef,
    MiddleTextRef,
    LastTextLeftRef,
    LastTextRightRef
  ])

  return (
    <Wrapper>
      <FirstText><BigTextInner active={bigTextActive}>growing</BigTextInner></FirstText>
      <BigTextWrapper>
        <ContentRowAnimated ref={rowOne} style={{zIndex:2}} active={ContentRowAnimatedActive}>
        <Statement>The ultimate goal is a clean world<br/>that would thrive and last.</Statement>
          <HangerOne ref={HangerOneRef}>
            <HangerInner>
              <Circle>
                <EarthEmoji ref={EarthReference} src={EarthImage} alt="Icon of an earth" />
              </Circle>
              <Line/>
            </HangerInner>
          </HangerOne>
          
        </ContentRowAnimated>
        <MiddleTextSpacer />
        <MiddleText ref={HealthReference}><MiddleTextInner ref={MiddelTextReference}>health</MiddleTextInner></MiddleText>
        <ContentRowSecond ref={rowTwo}>
          <Statement>Each of us humans have our unique<br/>superpowers to this cause, and here is a<br/>safe-place to unite — exchange, progress,<br/>and amplify our ideas to improve all health<br/>on Earth.</Statement> 
          <HangerTwo ref={HangerTwoRef}>
            <HangerInner>
              <Circle>
                <ShortEmoji ref={ShortReference} src={ShortImage} alt="Short haired person" />
                <LongEmoji ref={LongReference} src={LongImage} alt="Long haired person" />
              </Circle>
              <Line/>
            </HangerInner>
          </HangerTwo>
        </ContentRowSecond>
      </BigTextWrapper>
      <LastText><LastTextLeft ref={LastTextLeftReference}>toge</LastTextLeft><LastTextRight ref={LastTextRightReference}>ther</LastTextRight></LastText>
    </Wrapper>
  )
}

export default PartnerHead;