import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import { fontstack } from '../../utils/fontstack';
import { type } from '../../utils/type';
import { media } from '../../utils/mediaQuery';

const Textarea = styled.textarea` 
  ${fontstack.default}
  ${type('m-large')}
  font-weight: 300;
  color: #F3EFD7;
  border: 1px solid #F3EFD7;
  border-radius: 20px;
  outline: none;
  background: transparent;
  width: 100%;
  box-sizing: border-box;
  padding: 10px;
  margin: 0;
  min-height: 150px;

  ${media.large`
    ${type('large')}
    font-weight: 300;
    padding: 20px 30px;
    min-height: 300px;
  `}

  &::placeholder {
    color: #F3EFD7;
  }
`

const PartnerTextarea = React.forwardRef((props, ref) => {

  return (
    <Textarea ref={ref} {...props} />
  )
});

export default PartnerTextarea;

